<template>
    <div class="branch">
        <BackOnTop />
        <h2>{{ branchData.shop }}</h2>
        <div class="img_wrapper"><img :src="branchData.imgSrc" alt="" /></div>
        <div class="detail">
            <h5>服務項目</h5>
            <p class="service" v-html="branchData.service" />
        </div>
        <div class="detail">
            <h5>門市資訊</h5>
            <div class="info_branch">
                <ul>
                    <li
                        v-for="el in branchData.info"
                        :key="el.type"
                        :class="el.type"
                    >
                        <div class="th">{{ el.th }}</div>
                        <div class="td">
                            {{ el.td }}
                            <a
                                :href="`tel:+886${el.phone}`"
                                v-if="el.type === 'phone'"
                                >立即撥打</a
                            >
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <iframe
            :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyCveUcxpA3Qlu9vUYUvCjVOhyx9sQ_R_1Q&q=${branchData.address}`"
            width="100%"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
        ></iframe>
        <div class="detail">
            <h5>門市介紹</h5>
            <div class="intro">
                <ul>
                    <li
                        v-for="el in branchData.intro"
                        :key="el.type"
                        :class="el.type"
                    >
                        {{ el }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="detail">
            <h5>交通資訊</h5>
            <div class="traffic">
                <ul>
                    <li
                        v-for="el in branchData.traffic"
                        :key="el.type"
                        :class="el.type"
                    >
                        <div class="th">{{ el.th }}</div>
                        <div class="td">{{ el.td }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import { getStore } from '@/apis/api.js';

export default {
    name: 'LocationBranch',
    components: { BackOnTop },
    created() {
        var parent = this;

        getStore().then(function (rsp) {
            if (rsp) {
                console.log(rsp);
                parent.locationList = [];
                var id = parent.$route.query.id;
                for (let i in rsp) {
                    if (rsp[i].is_useful && rsp[i].id == id) {
                        var service_name_list = rsp[i].service.map(function (
                            obj
                        ) {
                            return obj.name;
                        });

                        parent.branchData = {
                            shop: rsp[i].name,
                            imgSrc: rsp[i].pic_url.replaceAll(
                                'http://',
                                'https://'
                            ),
                            service: service_name_list.join(' '),
                            info: [
                                {
                                    type: 'address',
                                    th: '門市地址',
                                    td: rsp[i].address,
                                },
                                {
                                    type: 'phone',
                                    th: '門市電話',
                                    td: rsp[i].phone,
                                    phone: rsp[i].phone
                                        .replace('-', '')
                                        .replace('0', ''),
                                },
                                { type: 'fax', th: '門市傳真', td: rsp[i].fax },
                                {
                                    type: 'open',
                                    th: '營業時間',
                                    td: rsp[i].business_hours,
                                },
                            ],
                            address: rsp[i].address,
                            intro: rsp[i].store_info,
                            traffic: [
                                {
                                    type: 'address',
                                    th: '交通位址',
                                    td: rsp[i].traffic_info.address,
                                },
                                {
                                    type: 'car',
                                    th: '自行開車',
                                    td: rsp[i].traffic_info.driver,
                                },
                                {
                                    type: 'public',
                                    th: '大眾交通',
                                    td: rsp[i].traffic_info
                                        .public_transportation,
                                },
                            ],
                        };
                    }
                }
            } else {
                console.log('getStore error');
            }
        });
        window.scrollTo(0, 0);
    },
    data: () => ({
        branchData: {
            shop: '內壢成章店',
            imgSrc: require('../../assets/images/img_04.jpg'),
            service:
                '寵物美容 寵物美容 寵物美容 寵物美容 寵物美容 寵物美容 寵物美容 寵物美容 ',
            info: [
                {
                    type: 'address',
                    th: '門市地址',
                    td: '桃園市中壢區成章一街162號',
                },
                { type: 'phone', th: '門市電話', td: '03-4335200' },
                { type: 'fax', th: '門市傳真', td: '03-4335255' },
                { type: 'open', th: '營業時間', td: '09:00-22:00' },
            ],
            address: '桃園市中壢區成章一街162號',
            intro: [
                '提供超過30 國以上優質犬貓飼料、主食罐、零食',
                '擁有 SPA 級的寵物美容,讓毛孩們放鬆又美麗',
                '各種玩具、寵物居家 DIY、戶外配件、小寵物商品,應有盡有',
                '專業獸醫師駐店 諮詢服務',
                '寵物保健營養食品諮詢服務',
                '提供最齊全的寵物生鮮食品項,滿足毛孩們吃美味健康的需求',
            ],
            traffic: [
                {
                    type: 'address',
                    th: '交通位址',
                    td: '中壢區成章一街全國電子旁',
                },
                {
                    type: 'car',
                    th: '自行開車',
                    td: '內壢火車站開車3-5 分鐘(忠孝路大樹藥局前配合停車場，消費可折抵1.5小時)',
                },
                {
                    type: 'public',
                    th: '大眾交通',
                    td: '內壢火車站前站下車後步行7分鐘',
                },
            ],
        },
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
.branch {
    text-align: left;
    padding-bottom: 40px;
    h2 {
        color: $color_main_first;
        font-size: 26px;
        font-weight: bold;
        padding: 12px 16px;
        line-height: 1;
        text-align: left;
    }
    h5 {
        color: $color_main_first;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        line-height: 45px;
        margin-bottom: 12px;
    }
    .detail {
        padding: 0 16px;
        margin-bottom: 12px;
        p,
        li {
            font-size: 14px;
            line-height: 1.5;
        }

        .intro {
            ul {
                padding-left: 20px;
            }
            li {
                list-style-position: outside;
                list-style: decimal;
                margin-top: 5px;
            }
        }
        .info_branch,
        .traffic {
            > ul {
                list-style: none;
            }

            li {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 12px;
                padding-left: 28px;
                line-height: 24px;
                // background-size: 90%;
                .th {
                    margin-right: 8px;
                }
                .td {
                    width: calc(100%);
                    a {
                        line-height: 30px;
                        display: inline-block;
                        border-radius: 15px;
                        background-color: $color_main_first;
                        padding: 0 10px;
                        color: #fff;
                        font-size: 16px;
                        float: right;
                    }
                }
            }
        }
    }
    .img_wrapper {
        width: calc(100% - 32px);
        margin: 12px auto;
        border-radius: 15px;
    }
    iframe {
        height: 60vw;
        max-height: 350px;
    }
}
</style>
